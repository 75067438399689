import * as React from "react";
import Layout from "../../../layouts/he";
import PageHead from "../../../components/shared/PageHead";
import Intro from "../../../components/shared/Intro";
import * as styles from "../../../styles/pages/articles/index.module.css";
import content from "../../../data/content/articles/find-invest/article-he";
import investment from "../../../assets/images/articles/investment.svg";
import tech from "../../../assets/images/articles/tech.svg";
import graph1 from "../../../assets/images/articles/net-down-HE.png";
import graph2 from "../../../assets/images/articles/net-up-HE.png";
import gearChat from "../../../assets/images/articles/gear-chat.svg";

const FindInvest = (props) => {
  const {
    sIntroSection,
    sIntro,
    sArticleContainer,
    sFI1Sec,
    sFI2Sec,
    sFI2SecWrap,
    sFI3Sec,
    sFI4Sec,
    sFI5Sec,
    sFI6Sec,
  } = styles;
  const { seo, intro, articleHeader, secP, thrdP, ffthP, sixP, dir } = content;
  return (
    <Layout location={props.location} lang="he">
      <PageHead pageHeadData={seo} />
      <article lang="he" className="article" dir={dir}>
        <Intro content={intro} styles={{ sIntroSection, sIntro }} />
        <div className={sArticleContainer}>
          <section className={sFI1Sec}>
            <p>{articleHeader.desc}</p>
          </section>
          <section className={sFI2Sec}>
            <div className={sFI2SecWrap}>
              <h2>{secP.title}</h2>
              <p>{secP.p[0]}</p>
              <div>
                <p>{secP.p[1]}</p>
                <img src={investment} alt="investment" />
              </div>
              <p>{secP.p[2]}</p>
            </div>
          </section>
          <section className={sFI3Sec}>
            <div>
              <h2>{thrdP.title}</h2>
            </div>
            <div>
              <p>{secP.p[0]}</p>
              <div>
                <img src={tech} alt="tech" />
                <p>{secP.p[1]}</p>
              </div>
              <p>{secP.p[2]}</p>
              <p>{secP.p[3]}</p>
            </div>
          </section>
          <section className={sFI4Sec}>
            <img src={graph1} alt="graph1" />
            <img src={graph2} alt="graph2" />
          </section>
          <section className={sFI5Sec}>
            <div>
              <img src={gearChat} alt="gearChat" />
              <h2>{ffthP.title}</h2>
            </div>
            <div>
              {ffthP.p.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
          </section>
          <section className={sFI6Sec}>
            <div>
              <p>{sixP.p}</p>
            </div>
          </section>
        </div>
      </article>
    </Layout>
  );
};

export default FindInvest;
