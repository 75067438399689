module.exports = {
  seo: {
    title : "מאמר - כיצד מאתרים משקיע - Jenko",
    description:  ""
  },
  intro : {
    title : "כיצד\u00A0מאתרים\u00A0משקיע"
  },
  articleHeader : {
    desc : "מתי הכי נכון לגייס משקיע? מה הקווים המנחים שיש לתת עליהם את הדעת כאשר מתכננים תהליך גיוס הון ממשקיעים? ממה נוטים לשכוח ומה יגדיל את סיכויי\u00A0ההצלחה?"
   },
   secP: {
    title: "הכל זה שאלה של\u00A0טיימינג",
    p:  [
        "לא מסובך לתחום את הדרך השגורה למדי שעוברת בין רעיון מבריק בראש לסטארט-אפ פעיל שמייצר רווחים בפועל. אף על פי שקיימים שינויים מינוריים בין ענף לענף, מדובר בתהליך בעל קווים מנחים ברורים שמתווים את הדרך עבור כל יזם או יזמת, בלי קשר למהות המוצר או תכליתו. אחת השאלות הכי משמעותיות שיקבעו האם מסע החיפוש אחר משקיע יניב פרי או לא, היא שאלת\u00A0הטיימינג.",
        'חיפוש משקיעים הוא תהליך אינדיבידואלי וחופשי וככזה, אין לו חוקים. ניתן לחפש משקיע בשלב הרעיון, בשלב שכבר קיים אבטיפוס ותכנית עסקית מגובשת או כאשר העסק כבר פעיל ועובד. שאלת הטיימינג משפיעה רבות על תהליך גיוס משקיעים. כלל האצבע מורה כי ככל שחיפוש המשקיע מתרחש בשלב מתקדם יותר של הדרך כאשר המוצר מגובש יותר, כך עולה הסיכוי לאיתור משקיעים ולגיוס הון. והרי זה הגיוני. כל אחד ירגיש יותר בנוח לשים את הביצים שלו בסל עם קבלות בשטח המעידות על ביטחון וכדאיות מאשר בסל שהכדאיות שלו מוטלת בספק ויכול מאד להיות שהרעיון מאחוריו יסתבר להיות "חתול\u00A0בשק".',
        "טעות נפוצה שיזמים רבים נוטים לעשות למשל, היא גיוס בשלב מוקדם מאד תמורת חלק גדול מדי בחברה. זה יכול להקשות על סבבי גיוסים עתידיים בהם יראו משקיעים אחרים כמה קטן חלקו של היזם בחברה ויעדיפו להמשיך לסטארט אפים בהם ליזם שליטה גדולה יותר. כמו בכל דבר בחיים, לא משנה באיזה שלב יתבצע גיוס ההון, יהיו לכך יתרונות וחסרונות. כך או כך, על מנת שתצליחו לגייס משקיע רציני אתם צריכים באמתחתכם חזון מגובש, תכנית עסקית ברורה, אב טיפוס או צוות פיתוח בדרך לאב טיפוס ומנהל או צוות מנהלים מגובש שיודע לנווט את\u00A0הספינה."    
    ]   },
thrdP: {
  title: "להכין את השטח",
    p: [
      "ברגע שהוחלט על הטיימינג המדויק, כדאי להכין את השטח לגיוס משקיע. אין לדעת מתי המשקיע הנכון יתדפק על מפתן הדלת ולעיתים הדבר קורה מהר יותר מכפי שדמיינתם. אתם רוצים להיות מוכנים כשזה קורה אחרת אתם עלולים לפספס את ההזדמנות. את ההכנה לגיוס משקיעים אפשר לחלק לשלושה רבדים: עבודה הכנה פנימית, עבודת הכנה חיצונית ולמידה על\u00A0המשקיעים.", 
      'כשמדברים על מוכנות של היזם, אנחנו מדברים על שני אספקטים. אספקט אחד הוא תנאי מקדים להמשך הדרך והוא מתמקד ברמת החשיבה והחזון. עליכם להיות בטוחים במוצר שלכם. להבין את התכלית שלו, לראות בצורה בהירה וממוקדת את הייחוד והבידול שלו ובמילים אחרות: להצליח להכניס אחרים "לתוך המוח שלכם" בקלות על מנת שיראו בדיוק את מה שאתם רואים. האספקט השני מתמקד בפרקטיקה של הדבר. תכנית עסקית ברורה ומפורטת שממפה את המודל העסקי, את המבנה הארגוני ואת התוואי המדויק שמראה את הטקטיקה שבאמצעותה תגיעו למטרות שלכם. עליכם למפות קשיים ומשוכות שעלולים לצוץ בדרך ותכניות גיבוי מפורטות שיתנו מענה פרקטי במידה ויצוצו. תצטרכו לספק מידע סמכותי ומהימן שמתבסס על מחקר עמוק בכל הקשור לשוק שלכם, לקהל היעד, לעלויות הפיתוח ולרווחים\u00A0הצפויים.',
       'הרובד השני של תהליך ההכנה לגיוס משקיע מדבר על הטיפול באלמנטים החיצוניים. אם יש לכם שותפים, תהליך גיוס המשקיעים אמור להתבצע תוך שיתוף פעולה מלא איתם. לא אחת קרו מצבים שהתגלה ניגוד אינטרסים בין משקיע לחבר בצוות הניהולי של היזם המגייס.  גילוי מאוחר של דבר כזה עלול לטרפד את העסקה כולה ולהאט אתכם בכמה צעדים מיותרים אחורה. מעבר לכך, קחו בחשבון שתזדקקו לבעלי מקצוע שיתמכו בתהליך. רו"ח ייעודי יעזור לכם עם מיפוי פיננסי והערכות שווי ועלויות מדויקים. בעל מקצוע כזה יוכל גם לסייע בגיבוש זהותו של המשקיע המדויק עבורכם והכוונה אליו. עוד תזקקו לשירותים משפטיים לצורך עיגון כל ההחלטות הרלוונטיות בקרב הצוות שלכם ומול\u00A0המשקיעים.',
        "החלק השלישי בדרך להיות מוכנים לגיוס הון הוא בחירת המשקיעים. למצוא משקיע מדויק היא משימה לא פחות חשובה מאשר למצוא מנהל או איש פיתוח מדויק ואולי אף יותר. איש צוות אפשר להחליף בקלות יחסית בעוד שמשקיע שכלל פעילות הסטארט אפ שלכם מבוססת על הונו, קצת יותר קשה להחליף. ניתן וכדאי כאמור להיוועץ בגורמים מקצועיים שידעו לכוון אתכם. הפרמטרים החשובים שיש לתת עליהם את הדעת בבחירתכם הם: הזיקה של המשקיע לענף שלכם, תאימות בין סכום הכסף שאתם מבקשים לגייס לרמת החוסן הכלכלי שלו, סוג המשקיע ומיקומו, השלב שבו אתם נמצאים, פוטנציאל הגדילה של שניכם\u00A0ועוד."
    ] 
  },
ffthP : {
    title: "שלב קבלת החלטה\u00A0סופית",
    p: [
      'יש רעיון, יש חזון, יש אבטיפוס, תכנית עסקית, עשיתם רעש ועוררתם התעניינות. הגיע הרגע ונקבעו פגישות עם משקיעים. עיקרון חשוב שלעיתים נוטים לשכוח ממנו הוא שמירה על סדר. נהלו את הנתונים שאתם אוספים מתהליך הגיוס שלכם באופן מסודר ונגיש. כדאי לערוך טבלה שתעזור לכם להשוות את מידת הערך שהמשקיעים השונים נותנים לכם. מה הפוטנציאל הכלכלי? מאיפה הם מגיעים ומה יש להם לתת לכם? מה רמת ההתלהבות שלהם מכם ומה רמת ההתלהבות שלכם מהם? עשו לכם הרגל לעדכן את המידע מיד בתום הפגישה כאשר הוא טרי\u00A0ועדכני.',
      'אתם תידרשו לשלוח למשקיעים מייל מסודר המציג חומרים בטרם הפגישה. כלל האצבע אומר: ככל שהמידע איכותי וקולע יותר, כך עולים הסיכויים לגיוס. את הפורמט שבו אתם מציגים את החומר יש להתאים לסוג המשקיעים וכמובן גם לאופי הענף שלכם. אם למשל המוצר שלכם קשור לדיגיטל עיצוב וחדשנות, כדאי לשלב באופן ההצגה שלכם אלמנטים חדשניים ומקוריים כמו למשל סרטון המציג את המוצר והצוות. אם, מאידך, אתם עומדים להציג את המוצר בפני קבוצת משקיעים סולידית, ארגנו את החומרים באופן רציני ומאופק\u00A0יותר.'
    ]
  },
sixP : {
    p: 'העיקרון שצריך להנחות אתכם בעת בחירת אופן הצגת החומרים למשקיעים הוא "לדבר בשפה שלהם". קשה מאד לגעת באדם אם אתם לא מדברים באותה השפה. ליזמים יש לעיתים נטייה להציג מוצרים חדשניים בשפת המוצרים ולא בשפת הקהל שלהם. זכרו מול מי אתם עומדים. קחו לדוגמא סטארט אפ שהמוצר שלו הוא משאית כבאים משוכללת בעלת טכנולוגיה מובנית שיודעת לייצר הדמיית תלת ממד בזמן שריפה. ההדמיה מאפשרת לראות מה קורה בתוך מבנה מבלי הצורך להיכנס פנימה. כך יכול הכבאי למפות את השטח בשניות מבחוץ. מוצר זה עשוי להציל חיים ובוודאי יעורר התעניינות רבה מצד מנהלי מערך הכבאות. כעת חשבו מי הם ראשי מערך הכבאות. בני כמה הם, מה לדעתכם הידע הטכנולוגי שעומד לרשותם וכמה עמוקים עלולים להיות פערי השיח הטכנולוגי שבינם לבין היזמים או המפתחים. הסיכוי להצלחה בפגישה עומד ביחס ישר ליכולת שלכם לגשר על הפער הזה. תעשו שיעורי בית ותגיעו מוכנים לקהל המשקיעים הספציפי שעומד\u00A0מולכם.'
  },
    dir : 'rtl'
  };